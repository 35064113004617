// Variable overrides

$main-blue: #0B4FFF;
$main-gray: #F3F3F6;
$danger-color: #CC1010;
$default-gray: #8B8B9D;



$primary: #FF5719 !default;
.sidebar .nav-link {
    background-color: darkcyan;
}
.sidebar .nav-link.active {
    background-color: #FF5719;
}
.sidebar .nav-dropdown-items {
    margin-left: 10px;
    font-size: 0.9em;
    // background-color: black;
}
.sidebar .nav-dropdown-items.active {
    margin-left: 10px;
}
.sidebar .nav-link .nav-icon {
    color: #FF5719;
    font-size: 1em;
}
.sidebar {
    background-color: burlywood;
}

.sidebar .nav-dropdown.open .nav-link {
    margin-left: 25px;
}

.sidebar .nav {
    margin-top: 10px;
}

.nav-icon {
    color: red;
}

.pagination > li > a
{
    background-color: white;
    color: #343a40;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a
{
    color: white;
    background-color: #343a40 !Important;
    border: solid 1px #343a40 !Important;
}

.pagination > .active > a:hover
{
    background-color: #343a40 !Important;
    border: solid 1px #343a40;
}

.bg-primary {
    background-color: #341e16!important;
}

$sidebar-width:                       250px !default;
$sidebar-nav-link-active-bg:                       #FF5719;
$sidebar-nav-link-active-icon-color:                       white;
$sidebar-nav-dropdown-bg: darkslategrey;

.ng-dropdown-panel { display: block !important; }

.p-datatable .p-sortable-column .p-sortable-column-icon{
    color: white;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
    color: white;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    color: white;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: white;
}
.p-datatable .p-sortable-column.p-highlight {
    color: white;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: white;
}
.p-datatable .p-sortable-column.p-highlight:hover {
    color: white;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: white;
}
.p-datatable-resizable>.p-datatable-wrapper {
    overflow-y: scroll;
    height: 550px; //960px
}  
